<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="活动名称" prop="activityId">
          <el-select v-model="searchForm.activityId" filterable style="width: 150px" placeholder="可搜索选项"
            @change="changeActivity">
            <el-option v-for="item in activityList" :key="item.id" :label="item.activityName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户">
          <el-select style="width: 150px" v-model="searchForm.userId" placeholder="请选择用户">
            <el-option v-for="item in userList" :key="item.id" :label="item.wechat ? item.wechat : item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select style="width: 150px" v-model="searchForm.status" placeholder="请选择订单状态">
            <el-option v-for="item in orderStatus" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="searchForm.startTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">活动订单列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">

          <el-button size="mini" type="primary" @click="pointPush" v-if="transferButton == '1'"
            :disabled="ids.length == 0">批量完成</el-button>
          <el-button size="mini" type="primary" @click="exportEvent" v-if="exportButton == '1'">订单导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectStatus">
            </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table ref="multipleTable2" style="width: 100%" :data="props.row.goodsShoppingOrderList">
                  <el-table-column prop="img" label="产品图片">
                    <template slot-scope="scope">
                      <div class="demo-image__preview" v-if="scope.row.img">
                        <el-image style="width: 60px" :src="scope.row.img">
                        </el-image>
                      </div>
                      <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
                    </template>
                  </el-table-column>
                  <el-table-column prop="title" label="产品名称" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="describe" label="产品描述" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="standard" label="产品规格"></el-table-column>
                  <el-table-column prop="num" label="数量"></el-table-column>
                  <el-table-column prop="purchasing" label="进货价"></el-table-column>
                  <el-table-column prop="price" label="单价"></el-table-column>
                  <el-table-column prop="totalPrice" label="商品总价"></el-table-column>
                  <!-- <el-table-column
                    prop="salePrice"
                    label="乡兴供货价"
                  ></el-table-column> -->
                  <el-table-column prop="point" label="积分"></el-table-column>
                  <el-table-column prop="type" label="状态">
                    <template slot-scope="scope">
                      <p v-if="scope.row.type == 0">正常</p>
                      <p v-if="scope.row.type == 1" style="color: #e6a23c">已退货</p>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100" label="操作">
                    <template slot-scope="scope">
                      <el-button size="mini" type="primary" @click="handleRefound(scope.row)"
                        v-if="orderReturnButton == '1'" :disabled="props.row.status != 0 && scope.row.type == 1">退货
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="activityName" label="活动名称"> </el-table-column>
            <el-table-column prop="activityName" label="活动类型">
              <template slot-scope="scope">
                <p>
                  {{scope.row.type==1?'立减':scope.row.type==2?'满折':scope.row.type==3?'满送':scope.row.type==4?'满赠':scope.row.type==5?'倍数':''}}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="num" label="商品数量"> </el-table-column>
            <el-table-column prop="totalPrice" label="订单总价">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #409eff">
                  已下单
                </p>
                <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
                  退货
                </p>
                <p v-else-if="scope.row.status == 2" style="color: #333">
                  取消
                </p>
                <p v-else-if="scope.row.status == 3" style="color: #67c23a">
                  已完成
                </p>
                <!-- <p v-else-if="scope.row.status == 4" style="color: rgb(245, 108, 108)">
                  已截单
                </p> -->
              </template>
            </el-table-column>
            <el-table-column prop="created" width="160" label="下单时间"></el-table-column>
            <el-table-column prop="deliveryTime" width="160" label="配送时间"></el-table-column>
            <el-table-column align="center" width="300" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleDetails(scope.row)" v-if="searchButton == '1'">详情
                </el-button>
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'"
                  :disabled="scope.row.status != 0">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleCancel(scope.row)" v-if="cancelButton == '1'"
                  :disabled="!scope.row.status == 0">取消</el-button>

              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 详情、编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="80%" :close="addEditCancel">
      <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" label-width="120px">
        <el-form-item label="用户" prop="userId">
          <el-select v-model="addEditForm.userId" filterable style="width: 300px"
            :disabled="detailsShow || dialogTitle == '编辑'" placeholder="可搜索选项" @change="changeUser">
            <el-option v-for="item in userList" :key="item.id" :label="item.wechat ? item.wechat : item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动名称" prop="activityId">
          <el-select v-model="addEditForm.activityId" filterable style="width: 300px" placeholder="可搜索选项"
            @change="changeActivity" :disabled="true">
            <el-option v-for="item in activityList" :key="item.id" :label="item.activityName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 活动信息 -->
        <el-form-item label="活动状态">
          <el-input style="width: 300px" :disabled="true"
            :value="activityData.status==0?'待发布':activityData.status==1?'已发布':''"></el-input>
        </el-form-item>
        <el-form-item label="活动类型">
          <el-input style="width: 300px" :disabled="true"
            :value="activityData.type==1?'立减':activityData.type==2?'满折':activityData.type==3?'满送':activityData.type==4?'满赠':activityData.type==5?'倍数':''">
          </el-input>
        </el-form-item>
        <el-form-item label="产品总数">
          <el-input style="width: 300px" :disabled="true" v-model="addEditForm.num"></el-input>
        </el-form-item>
        <el-form-item label="产品总价">
          <el-input style="width: 300px" :disabled="true" v-model="addEditForm.totalPrice"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker :disabled="true" style="width: 300px" v-model="activityData.startTime" type="date"
            value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker style="width: 300px" :disabled="true" v-model="activityData.endTime" type="date"
            value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配送时间">
          <el-input style="width: 300px" :disabled="detailsShow" v-model="addEditForm.deliveryTime"></el-input>
        </el-form-item>
        <el-form-item label="活动规则" style="display:block">
          <el-input type="textarea" rows="5" style="width: 300px" :disabled="true" v-model="activityData.describe">
          </el-input>
        </el-form-item>

        <el-form-item label="添加商品" style="margin-top: 30px;" v-if="dialogTitle == '编辑'">
          <el-select v-model="goodsId" filterable style="width: 300px" placeholder="可搜索选项" @change="changeGoodsEvents">
            <el-option v-for="item in selectGoods" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div style="padding:20px 50px;">
          <template>
            <el-table stripe show-summary :summary-method="getSummaries" ref="multipleTable2"
              @selection-change="handleSelectionChange" style="width: 100%" :data="goodsData">
              <!-- <el-table-column v-if="!detailsShow" type="selection" width="55" :selectable="selectStatus">
            </el-table-column> -->
              <el-table-column prop="img" label="产品图片" min-width="140">
                <template slot-scope="scope">
                  <div class="demo-image__preview" v-if="scope.row.img">
                    <el-image style="width: 60px" :src="scope.row.img">
                    </el-image>
                  </div>
                  <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
                </template>
              </el-table-column>
              <el-table-column prop="title" label="产品名称" show-overflow-tooltip></el-table-column>
              <el-table-column prop="describe" label="产品描述" show-overflow-tooltip></el-table-column>
              <el-table-column prop="standard" label="产品规格"></el-table-column>
              <el-table-column prop="num" label="数量" min-width="180" v-if="!detailsShow">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.num" @change="handleGoodsNumChange($event,scope.row)" :min="1"
                    label="描述文字"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量" v-if="detailsShow" min-width="180"></el-table-column>
              <el-table-column prop="purchasing" label="进货价">
                 <template slot-scope="scope">{{ scope.row.purchasing }} 元</template>
              </el-table-column>
              <el-table-column prop="price" label="单价">
                <template slot-scope="scope">{{ scope.row.price }} 元</template>
              </el-table-column>
              <el-table-column prop="totalPrice" label="商品总价">
                <template slot-scope="scope">{{ scope.row.totalPrice }} 元</template>
              </el-table-column>
              <el-table-column prop="point" label="积分"></el-table-column>
              <el-table-column prop="type" label="状态">
                <template slot-scope="scope">
                  <p v-if="scope.row.type == 0">正常</p>
                  <p v-if="scope.row.type == 1" style="color: #e6a23c">已退货</p>
                </template>
              </el-table-column>
              <el-table-column align="center" width="200" label="操作">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="handleZsGoods(scope.row)" v-if="activityData.type == 4"
                    :disabled="!scope.row.goodsList">查看赠品</el-button>
                  <el-button size="mini" type="danger" @click="handleGoodsDels(scope.row)" v-if="updateButton == '1'"
                    :disabled="scope.row.type!=0 || detailsShow ">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 优惠明细 -->
            <el-table v-if="infoTablkeData.length>0" style="width: 100%" :data="infoTablkeData">
              <el-table-column prop="img" label="产品图片" width="100">
                <template slot-scope="scope">
                  <div class="demo-image__preview" v-if="scope.row.img">
                    <el-image style="width: 60px" :src="scope.row.img">
                    </el-image>
                  </div>
                  <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
                </template>
              </el-table-column>
              <el-table-column prop="title" label="产品名称" show-overflow-tooltip></el-table-column>
              <el-table-column prop="standard" label="产品规格"></el-table-column>
              <el-table-column prop="num" label="数量"></el-table-column>
              <el-table-column prop="price" label="价格">
                <template slot-scope="scope">{{ scope.row.price }} 元</template>
              </el-table-column>
              <el-table-column prop="describe" label="优惠规则" show-overflow-tooltip></el-table-column>
            </el-table>
          </template>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" :loading="submitBtnLoad" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 订单导出 -->
    <el-dialog title="订单导出" :visible.sync="exportShow" width="60%">
      <el-form :model="exportForm" ref="exportForm" :inline="true" label-width="120px">
        <el-form-item label="选择用户" prop="userId">
          <el-select v-model="exportForm.userId" filterable clearable style="width: 300px" placeholder="可搜索选项"
            @change="changeUser">
            <el-option v-for="item in userList" :key="item.id" :label="item.wechat ? item.wechat : item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动名称" prop="activityId">
          <el-select v-model="exportForm.activityId" filterable style="width: 300px" placeholder="可搜索选项">
            <el-option v-for="item in activityList" :key="item.id" :label="item.activityName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="exportForm.status" clearable style="width: 300px">
            <el-option v-for="item in orderStatus" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="exportForm.startTime" type="datetime" style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="exportForm.endTime" type="datetime" style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false;exportForm = {};
        exportCategoryId = [];">取 消</el-button>
        <el-button @click="
          exportForm = {};
          exportCategoryId = [];
        ">清 空</el-button>
        <el-button type="primary" @click="orderExport">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 退货 -->
    <el-dialog title="退货" :visible.sync="returnShow" width="35%">
      <el-form :model="returnForm" ref="returnForm" :rules="returnRules" :inline="true" label-width="120px">
        <el-form-item label="商品名称">
          <el-input style="width: 300px" v-model="returnForm.title" placeholder="请输入商品名称" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="商品数量" prop="returnGoodsNum">
          <el-input style="width: 300px" type="Number" min="0" :max="returnForm.num" @change="goodsNumChange"
            v-model="returnForm.returnGoodsNum" placeholder="请输入商品数量"></el-input>
        </el-form-item>
        <el-form-item label="退货原因">
          <el-input style="width: 300px" type="textarea" v-model="returnForm.reason" placeholder="请输入退货原因"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hanleCancel('returnShow')">取 消</el-button>
        <el-button type="primary" @click="returnSubmit('returnForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看赠品 -->
    <el-dialog title="赠品" :visible.sync="zengPinShow" width="60%">
      <el-table style="width: 100%" :data="zengPinData">
        <el-table-column prop="imgs" label="产品图片">
          <template slot-scope="scope">
            <div class="demo-image__preview" v-if="scope.row.imgs">
              <el-image style="width: 60px" :src="scope.row.imgs">
              </el-image>
            </div>
            <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="产品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="describe" label="产品描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="standard" label="产品规格"></el-table-column>
        <el-table-column prop="color" label="颜色"></el-table-column>
        <el-table-column prop="goodsNum" label="数量"></el-table-column>
        <!-- <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column prop="describe" label="优惠规则" show-overflow-tooltip></el-table-column> -->
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hanleCancel('zengPinShow')">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 导出订单表格 -->
    <table id="export_table" border="1" cellspacing="0">
      <thead>
        <tr>
          <th>微信昵称</th>
          <th>真实姓名</th>
          <th>门店名称</th>
          <th>联系方式</th>
          <th>所属乡镇</th>
          <th>地址</th>
          <th>购买数量</th>
          <th>产品总价</th>
          <th>优惠价格</th>
          <!-- 商品列表 -->
          <th>产品名称</th>
          <th>规格</th>
          <th>进货价</th>
          <th>单价</th>
          <th>数量</th>
          <th>总价</th>
          <th>订单状态</th>
          <th>下单时间</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in exportData" :key="index" style="width:100%">
          <td>{{item.wechat}}</td>
          <td>{{ item.username}}</td>
          <td>{{ item.shopName}}</td>
          <td>{{ item.phone}}</td>
          <td>{{ item.towns}}</td>
          <td>{{ item.address}}</td>
          <td>{{ item.num}}</td>
          <td>{{ item.totalPrice}}</td>
          <td>{{ item.price}}</td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.title}}</p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.standard}}</p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.purchasing}}</p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.price}}</p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.num}}</p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.totalPrice}}</p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.status == 0 ? '已下单' : childs.status ==
            1 ? '退货' : childs.status == 2 ? '取消' : childs.status == 3?'已完成':''}}
            </p>
          </td>
          <td>
            <p v-for="(childs,ind) in item.orderDetailList	" :key="ind">{{ childs.created}}</p>
          </td>
        </tr>

      </tbody>
    </table>
    <!-- <tr v-for="(childs,ind) in item.orderDetailList	" :key="ind">
      <td>{{ item.standard}}</td>
      <td>{{ item.purchasing}}</td>
      <td>{{ item.price}}</td>
      <td>{{ item.num}}</td>
      <td>{{ item.totalPrice}}</td>
      <td>{{ item.created}}</td>
    </tr> -->
  </div>
</template>

<script>
import dataTotal_userVue from '../dataTotal/dataTotal_user.vue';
export default {
  name: "goodsIndex",
  data() {
    return {
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      transferButton: "",
      orderReturnButton: "",
      exportButton: "",
      cancelButton:'',
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        activityId: [{ required: true, message: "请选择活动", trigger: "change" }],
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        num: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 订单类型
      orderType: [
        {
          label: "限时订单",
          id: "1",
        },
        {
          label: "限量订单",
          id: "2",
        }, {
          label: "限购订单",
          id: "3",
        }, {
          label: "长期热销",
          id: "4",
        },
      ],
      orderStatus: [
        {
          label: "已下单",
          id: "0",
        },
        {
          label: "退货",
          id: "1",
        },
        {
          label: "取消",
          id: "2",
        },
        {
          label: "已完成",
          id: "3",
        },
        // {
        //   label: "已截单",
        //   id: "4",
        // },

      ],
      submitBtnLoad: false,
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      // 下拉商品
      selectGoods: [],
      userList: [],
      saleGoodsInfo: {},
      // 选中用户的adcode
      userAdcode: "",
      exportShow: false,
      exportForm: {},
      exportCategoryId: [],
      returnForm: {},
      returnShow: false,
      returnRules: {
        returnGoodsNum: [
          { required: true, message: "请输入商品数量", trigger: "blur" },
        ],
      },
      adcode: sessionStorage.getItem('adcode'),
      goodsUnit:[],
      goodsType:[],
      //活动列表
      activityList:[],
      // 活动所属仓库ID
      warehouseId:'',
      // 选中的活动数据
      activityData:{},
      // 活动下的商品列表
      goodsData:[],
      goodsId:'',
      // 满足的活动规则
      rules:{},
      // 订单优惠后的总价
      newPrice:0,
      // 优惠明细表
      infoTablkeData:[],
      // 优惠金额
      cutAmount:0,
      // 满送件数
      msNum:0,
      // 赠品弹框
      zengPinShow:false,
      zengPinData:[],
      // 导出数据
      exportData:[],
    };
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    this.getUserList();
    this.getActivityList();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_order_add") {
        this.addButton = "1";
      }else if(item.authority == "goods_order_update") {
        this.updateButton = "1";
      }else if (item.authority == "goods_order_query") {
        this.searchButton = "1";
      }else if (item.authority == "goods_order_return") {
        this.orderReturnButton = "1";
      }else if (item.authority == "goods_order_cancel") {
        this.cancelButton = "1";
      }else if (item.authority == "goods_order_transfer") {
        this.transferButton = "1";
      } else if (item.authority == "goods_order_export") {
        this.exportButton = "1";
      } 
    });
  },
  methods: {
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsOrder", {
          params: _this.searchForm,
        })
        .then((res) => {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.goodsImg) {
                if (item.goodsImg.indexOf(",") != -1)
                  item.icons = item.goodsImg.split(",")[0];
                else item.icons = item.goodsImg;
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显商品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //获取活动商品下拉列表
    getSelectGoods() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getActivityGoodsList", {
          params: {
            warehouseId: _this.warehouseId,
            type:5,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            res.data.data.forEach(item => {
              item.name += ` - ${item.standard}`
              if (item.isTopCarriage == 0) {
                item.name = item.name + '（未上架）'
              } else if (item.isTopCarriage == 2) {
                item.name = item.name + '（已下架）'
              }
            });
            _this.selectGoods = res.data.data;
          }
        });
    },
    // 获取用户下拉列表
    getUserList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUser")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.userList = res.data.data;
          }
        });
    },
    // 获取活动下拉列表
    getActivityList(){
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/activity/activityList")
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.activityList = res.data.data;
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      console.log(this.searchForm.categoryId);
      this.searchForm.categoryId = this.searchForm.categoryId
        ? this.searchForm.categoryId.join(",")
        : "";
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 修改
    handleEdit(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsOrder/details", {
          params: { id: row.id },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.activityList.forEach(item=>{
              if(data.activityId == item.id){
                that.activityData = item;
                that.warehouseId = item.warehouseId;
                that.getSelectGoods();
              };
            })
            that.addEditForm = data
            that.goodsData = data.goodsShoppingOrderList
            that.countRules(that.activityData,that.goodsData)
            that.detailsShow = false;
            that.dialogTitle = "编辑";
            that.dialogFormVisible = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.fileList = [];
      this.imgData = [];
      this.$refs.addEditForm.clearValidate();
    },
    // 编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitBtnLoad = true;
          if (this.addEditForm.num <= 0) {
            this.$message({
              message: "商品数量不能小于1",
              type: "warning",
            });
            this.submitBtnLoad = false;
            return;
          }
          if (this.dialogTitle == "编辑") {
            console.log(this.goodsData)
            let goodsList = [];
            this.goodsData.forEach(item=>{
              let listJson = {
                id:item.goodsId,
                num:item.num
              }
              goodsList.push(listJson)
            })
            let json = {
              activityId:this.activityData.id,
              deliveryTime:this.addEditForm.deliveryTime,
              id:this.addEditForm.id,
              num:this.addEditForm.num,
              totalPrice:this.addEditForm.totalPrice,
              type:this.activityData.type,
              userId:this.addEditForm.userId,
              list:goodsList
            }
            console.log(json)
            this.$axios({
              url:this.$axios.defaults.basePath + "/goodsOrder/update",
              method:"POST",
              headers: {
                "Content-Type": "application/json",
              },
              data:JSON.stringify(json)
              })
              .then((res) => {
                console.log(res);
                _this.dialogFormVisible = false;
                _this.submitBtnLoad = false;
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  _this.listEvent();
                } else {
                  _this.$message({
                    message: res.data.errmsg,
                    type: "warning",
                  });
                }
              });
          }
        } else {
          _this.submitBtnLoad = false;
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/goodsMall/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑删除活动商品
    handleGoodsDels(row){
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var newArr = [];
          this.goodsData.forEach(item=>{
            if(row.id != item.id){
              newArr.push(item)
            }
          })
          this.goodsData = newArr
          //随着数量改变获取满足的规则
            this.countRules(this.activityData,this.goodsData);;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.saleGoodsInfo.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    searchUnitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.searchForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsOrder/details", {
          params: { id: row.id },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.activityList.forEach(item=>{
              if(data.activityId == item.id){
                that.activityData = item;
                that.warehouseId = item.warehouseId;
                that.getSelectGoods();
              };
            })
            that.addEditForm = data
            that.goodsData = data.goodsShoppingOrderList
            that.countRules(that.activityData,that.goodsData)
            that.detailsShow = true;
            that.dialogTitle = "详情";
            that.dialogFormVisible = true;


          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 下拉选择商品
    changeGoodsEvents(e) {
      console.log(e);
      let _this = this;
      let nextTip = false;
      if (e) {
        _this.goodsData.forEach(item=>{
          if(e == item.goodsId){
            _this.$message({
              message: '已有此商品，不可重复添加',
              type: "warning",
            });
            nextTip =true;
            return
          }
        });
        if(nextTip == false){
          _this.selectGoods.forEach((item) => {
            if (item.id == e) {
              console.log(item)
              item.title = item.name
              item.num = 1
              item.goodsId = item.id
              item.img = item.standardImg
              item.price = item.salePrice
              _this.$set(item,'totalPrice',item.num*item.salePrice)
              // item.totalPrice = item.num*item.salePrice
              _this.goodsData.push(item);
            }
          });
          
          //随着数量改变获取满足的规则
          _this.countRules(_this.activityData,_this.goodsData);
          // 重新渲染表格
          _this.$nextTick(()=>{
            _this.$refs['multipleTable2'].doLayout();
          }) 
        }
        
      }
    },
    // 取消
    handleCancel(row) {
      console.log(row);
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsOrder/cancel", {
          params: {
            id: row.id,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 选择用户
    changeUser(e) {
      this.userList.forEach((item) => {
        if (item.id == e) {
          if (item.adcode) {
            this.userAdcode = item.adcode;
            this.getSelectGoods();
          } else {
            this.$message({
              message: "此用户不是团长，只有团长才能下单",
              type: "warning",
            });
            this.addEditForm.userId = "";
          }
        }
      });
    },
    // 选择活动
    changeActivity(e){
      this.activityList.forEach(item=>{
        if(e == item.id){
          this.activityData = item;
          this.warehouseId = item.warehouseId;
        } 
      })
    },
    // 发放积分
    pointPush() {
      let _this = this;
      console.log(this.ids);
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get(this.$axios.defaults.basePath + "/goodsOrder/transfer", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              console.log(res);
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 表格复选框是否可选中
    selectStatus(row, index) {
      if (row.status == 1 || row.status == 2 || row.status == 3) {
        //已完成、取消、退货状态不可选中
        return false;
      } else {
        return true;
      }
    },
    changeGoodsType(e) {
      console.log(e);
    },
    exportEvent() {
      this.exportShow = true;
      // this.getSelectGoods(this.adcode)
    },
    // 订单导出
    orderExport() {
      let _this = this;
      if (this.exportCategoryId && this.exportCategoryId.length > 0) {
        this.exportForm.categoryId = this.exportCategoryId.join(",");
      }
      console.log(this.exportCategoryId, this.exportForm);
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsOrder/orderExport", {
          params: _this.exportForm,
        })
        .then((res) => {
          console.log(res);
          if(res.data.errcode == 0){
            _this.exportData = res.data.data;
            _this.$nextTick(()=>{
              var html = "<html><head><meta charset='UTF-8'></head><body>" + document.getElementById("export_table").outerHTML + "</body></html>";
              var blob = new Blob([html], { type: "application/vnd.ms-excel" });
              // let tableHtml = document.getElementById('export_table');
              // let blob = new Blob([tableHtml], { type: "application/vnd.ms-excel" });
              // blob = new Blob([String.fromCharCode(0xFEFF), blob], { type: blob.type });
              //设置链接
              let link = window.URL.createObjectURL(blob);
              let a = document.createElement("a");    //创建a标签
              a.download = "活动订单.xls";  //设置被下载的超链接目标（文件名）
              a.href = link;                            //设置a标签的链接
              document.body.appendChild(a);            //a标签添加到页面
              a.click();                                //设置a标签触发单击事件
              document.body.removeChild(a);            //移除a标签
              _this.exportShow = false;
              _this.$message({
                message: "导出成功",
                type: "success",
              });
              _this.exportForm = {};
              _this.exportCategoryId = [];
            })

          }else{
            _this.$message({
              message:res.data.errmsg,
              type:'warning'
            })
          }
          // _this.exportData = res.data.data;
          // var eleLink = document.createElement("a");
          // eleLink.download = "活动订单.csv";
          // eleLink.style.display = "none";

          // var blob = new Blob([res.data]);
          // eleLink.href = URL.createObjectURL(blob);
          // console.info(blob);
          // // 触发点击
          // document.body.appendChild(eleLink);
          // eleLink.click();
          // _this.exportShow = false;
          // _this.$message({
          //   message: "导出成功",
          //   type: "success",
          // });
          // _this.exportForm = {};
          // _this.exportCategoryId = [];
          // // 然后移除
          // document.body.removeChild(eleLink);
        });
    },
    changeGoods(e) {
      console.log(e);
    },
    // 订单退货
    handleRefound(row) {
      this.returnForm = row;
      console.log(row);
      this.returnShow = true;
    },
    returnSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.returnForm.returnGoodsNum > this.returnForm.num) {
            this.$message({
              message: "退货数量不能大于购买数量",
              type: "warning",
            });
            return;
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + "/goodsOrder/orderReturn",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              id: _this.returnForm.id,
              goodsId: _this.returnForm.goodsId,
              returnGoodsNum: _this.returnForm.returnGoodsNum,
              reason: _this.returnForm.reason,
            },
          }).then((res) => {
            console.log(res);
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.returnShow = false;
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    hanleCancel(s) {
      this[s] = false;
    },
    changeGoodsNum(e) {
      console.log(e);
      if (e <= 0) {
        this.$message({
          message: "商品数量不能小于0",
          type: "warning",
        });
        this.addEditForm.num = 1;
      }
      // addEditForm.num
    },
    goodsNumChange(e) {
      if (e > this.returnForm.num) {
        this.$message({
          message: "退货数量不能大于购买数量",
          type: "warning",
        });
        this.$set(this.returnForm, "returnGoodsNum", 1);
        return;
      }
    },
    // 合计
    getSummaries(param) {
      console.log(param)
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          if (this.rules.threshold){
            let unit = this.rules.unit == 1 ? '件' : '元'
            if (this.activityData.type == 1) { //立减
              sums[index] = '总计：满' + this.rules.threshold + unit + '立减' + this.rules.discounts + '元';
            } else if (this.activityData.type == 2) { //满折
              sums[index] = '总计：满' + this.rules.threshold + unit + '打' + this.rules.discounts + '折';
            }
            else if(this.rules.type == 3){ //满送
              sums[index] = '总计：满'+this.rules.threshold+unit+'送'+this.rules.discounts+'件';
            }
            else if (this.activityData.type == 4) { //满赠
              sums[index] = '总计：';
            } else if (this.activityData.type == 5) { //倍数
              var upTxt = ''; 
              if(this.rules.upLimit == 0){
                upTxt = '上不封顶'
              }else{
                upTxt = '最多减' + this.rules.upLimit+'元'
              }
              sums[index] = '总计：每满' + this.rules.threshold + unit + '减' + this.rules.discounts + '元，' + upTxt;
            } else {
              sums[index] = '总计：';
            }
          } else {
            sums[index] = '总计：';
          }
          return;
        }
        if(index == 1){
          if(this.cutAmount){
            sums[index] = '-'+this.cutAmount
          }else{
            sums[index] = '';
          }
        }
        // 只合计总数量和总价
        if(column.property == "num"){
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            if(this.activityData.type == 3){
              sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = '订单商品'+ sums[index]+'件，赠送'+this.msNum+'件，共'+this.addEditForm.num+'件';
            }else{
              sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = '共'+sums[index]+'件';
            }
          } else {
            sums[index] = '';
          }
        }else if(column.property == "totalPrice"){
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                if(this.activityData.type && this.activityData.type != 4){
                  return this.newPrice
                }else{
                  return prev + curr;
                }
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index]+' 元';
          } else {
            sums[index] = '';
          }
        }
      });

      return sums;
    },
    // 修改活动商品数量
    handleGoodsNumChange(e,row){
      console.log(e)
      row.totalPrice = (e*row.price).toFixed(2)
      //随着数量改变获取满足的规则
      this.countRules(this.activityData,this.goodsData);
    },
    // 优惠金额计算结果
    accSub(arg1, arg2) {
      var r1, r2, m, n;
      try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2));
      n = (r1 >= r2) ? r1 : r2;
      return new Number(((arg1 * m - arg2 * m) / m)).toFixed(n);
    },
    // 计算符合的优惠规则
    countRules(activityData,goodsData){
      console.log(activityData,goodsData)
      let type = activityData.type
      let ruleList = activityData.activityRuleList
      this.rules = {};
      // 获取订单总价
        var totalPrice = 0;; //总商品金额
        var totalNum = 0; //总商品数量
        goodsData.forEach((item,index)=>{
          totalNum+=item.num;
          totalPrice+=Number(item.totalPrice)
        })
      if(ruleList){
        var last = ruleList[ruleList.length-1]
        
        // 判断单位是件/元
        let baseNum = 0; 
        if(last.unit == 1){ //按总件数计算
          baseNum = totalNum
        }else{ //按总价计算
          baseNum = totalPrice
        }
        if(baseNum>last.threshold){ 
          this.rules = last
        }else if(baseNum<ruleList[0].threshold){ 
          this.rules = {}
        }else{
          for(var i=0;i<ruleList.length;i++){
            if(baseNum == ruleList[i].threshold){
              this.rules = ruleList[i]
            }else if(baseNum>ruleList[i].threshold && baseNum<ruleList[i+1].threshold){
              console.log(ruleList[i])
              this.rules = ruleList[i]
            }
          }
          
        }
        console.log(this.rules)
        var discounts = 0;
        if (this.rules.discounts){
          discounts = this.rules.discounts; //优惠
        }else{
          discounts = 0; //优惠
        }
      }
      
      if(type ==1){ 
        // -----------立减------------------
        // 所有商品满足条件立减对应金额
        this.newPrice = (totalPrice - discounts).toFixed(2)
        this.cutAmount = discounts
        this.$set(this.addEditForm,'num',totalNum)
        this.$set(this.addEditForm,'totalPrice',this.newPrice)

      }else if(type ==2){ 
        // -----------满折------------------
        // 所有商品满足条件享受对应折扣
        this.newPrice = discounts == 0 ? totalPrice:(totalPrice * (discounts/10)).toFixed(2)
        //  discounts == 0 ?0:totalPrice - totalPrice * (Number(discounts)/10)
        if (discounts){
          this.cutAmount = new Number((this.accSub(totalPrice, totalPrice * (Number(discounts) / 10)))).toFixed(2)
        }else{
          this.cutAmount = ''
        }
        
        this.$set(this.addEditForm,'num',totalNum)
        this.$set(this.addEditForm,'totalPrice',this.newPrice)
      }else if(type == 3){ 
        // -----------满送------------------
        // 单个商品满足条件赠送相同商品
        let onePrice = 0; //单个商品的总价
        let oneRules = {};//单个商品符合的优惠规则
        let unit = ''; //单位
        this.msNum = 0;
        this.infoTablkeData = [];
        goodsData.forEach((item,index)=>{
          let baseNum = 0;
          if(last.unit == 1){ //按单个商品总件数计算
            unit = "件"
            baseNum = item.num
          }else{ //按单个商品总价计算
            unit = "元"
            baseNum = item.totalPrice
          }
          if(baseNum>last.threshold){ 
            oneRules = last
          }else if(baseNum<ruleList[0].threshold){ 
            oneRules = {}
          }else{
            for(var i=0;i<ruleList.length;i++){
              if(baseNum == ruleList[i].threshold){
                oneRules = ruleList[i]
              }else if(baseNum>ruleList[i].threshold && baseNum<ruleList[i+1].threshold){
                console.log(ruleList[i])
                oneRules = ruleList[i]
              }
            }
          }
          if(oneRules.discounts){
            let num = oneRules.discounts; //单个商品的赠送件数
            this.msNum+=Number(num); //总的赠送件数
            totalNum+=Number(num); //总商品数+赠送件数 = 总件数
            let goodsItem = JSON.parse(JSON.stringify(item));
            goodsItem.num = num;
            goodsItem.price = 0;
            goodsItem.describe = '满'+oneRules.threshold+unit+'送'+num+'件';
            this.infoTablkeData.push(goodsItem);
          }
          
        })
        console.log(this.infoTablkeData)
        
        this.newPrice = totalPrice.toFixed(2)
        this.$set(this.addEditForm,'num',totalNum)
        this.$set(this.addEditForm,'totalPrice',this.newPrice)

      }else if(type == 4){ 
      // -----------满赠------------------
      // 单个商品满足条件赠送赠品

      this.$set(this.addEditForm,'totalPrice',totalPrice)

      }else if(type == 5){ 
      // -----------倍数------------------
      // 所有商品满足条件享受优惠
        this.rules = ruleList[0]
        var multiple =0; //倍数
        var upLimit = this.rules.upLimit; //优惠上线 0表示没有上线
        var yHPrice = 0;
        const discounts = this.rules.discounts ? this.rules.discounts : 0; //优惠
        if(totalPrice>=this.rules.threshold){
          multiple = Math.floor(totalPrice / this.rules.threshold)
        }
        if (upLimit == 0){
          yHPrice = multiple * discounts
        } else if (upLimit < multiple * discounts){
          yHPrice = upLimit
        }else{
          yHPrice = multiple * discounts
        }
        
        this.cutAmount = yHPrice
        this.newPrice = (totalPrice - yHPrice).toFixed(2)
        this.$set(this.addEditForm,'num',totalNum)
        this.$set(this.addEditForm,'totalPrice',this.newPrice)
      }
    },
    // 查看赠品
    handleZsGoods(row){
      console.log(row)
      this.zengPinData = row.goodsList;
      this.zengPinShow = true;
    },
  },
};
</script>

<style scoped>
#export_table{
  width: 60%;
  margin: 40px 0;
  text-align: center;
  display: none;
}
#export_table tr td,
#export_table tr th{
  border:1px solid #333;
}
#export_table tr td p{
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  border-top:1px solid #333;
}
</style>
